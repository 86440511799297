/* eslint-disable max-lines */
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfirmService } from '../confirm/confirm.service';
import {
  ICart,
  ICartGeneric,
  ICartNonGeneric,
  ICartOfferArchivePosition,
  ILicenseAddress,
  IOfferCartResponse,
  IOrderCartResponse,
  ISetCartRequest,
} from '../interfaces/cart.interface';
import { IDocument } from '../interfaces/document.interface';
import { IGenericPosition } from '../interfaces/generic-position.interface';
import { ISetAddressData } from './address.service';
import { DialogService } from './dialog.service';
import { ErrorHandlingService } from './error-handling.service';
import { GenericPositionService } from './generic-position.service';
import { TranslationService } from './translation.service';
import { AuthenticationService } from './authentication.service';
import { DocumentPositionSetConfigurationService } from './document-position-set-configuration.service';
import { VatService } from './vat.service';
import { CartMappers } from '../mapping-functions/cart-mappers';
import { BaseCartService } from './base-cart.service';
import moment from 'moment';
import { LoadingService } from './loading.service';
import { DataLayerService } from './data-layer.service';

/**
 * NEVER ! inject this cart service subclass, ALWAYS ! inject BaseCartService.
 * The cart service providers and the cart service factory will decide which cart subclass to use.
 */
export class CartService extends BaseCartService {
  constructor(
    protected override _translationService: TranslationService,
    private _httpClient: HttpClient,
    protected override _genericPositionsService: GenericPositionService,
    protected override _errorHandlingService: ErrorHandlingService,
    private _matSnackBar: MatSnackBar,
    protected override _dialogService: DialogService,
    protected override _confirmService: ConfirmService,
    private _authenticationService: AuthenticationService,
    protected override _documentPositionSetConfigurationService: DocumentPositionSetConfigurationService,
    protected override _vatService: VatService,
    protected override _loadingService: LoadingService,
    protected override _dataLayerService: DataLayerService
  ) {
    super(
      _translationService,
      _errorHandlingService,
      _genericPositionsService,
      _dialogService,
      _confirmService,
      _documentPositionSetConfigurationService,
      _vatService,
      _loadingService,
      _dataLayerService
    );

    this.getCartFromBackend().subscribe();

    this._handleCartChanges((cart, message) => this._setCart(cart, message)).subscribe();
  }

  public getCartFromBackend(): Observable<ICartGeneric> {
    const cartTemporarySessionUUID = this._authenticationService.getCartTemporarySessionUUIDFromLocalStorage();

    if (cartTemporarySessionUUID || this._authenticationService.authenticationState$.value) {
      return this._httpClient.get<Partial<ICartNonGeneric>>(`api/cart` + (cartTemporarySessionUUID ? `/${cartTemporarySessionUUID}` : '')).pipe(
        map((cart) => {
          if (!cart) {
            cart = {
              CartDeliveryAddressAdditionalInformation: '',
              CartDescription: '',
              CartInvoiceAddressAdditionalInformation: '',
              CartPositions: [],
              CartReference: '',
              CartRemark: '',
              CustomerCanUsePayPal: false,
              CustomerPaymentConditionDescription: { de: '', en: '' },
              PaymentHasTax: false,
              CartDeliveryDateTypeID: undefined,
              CartDeliveryDate: '',
            };
          }

          return this._mapCartToGenericCart(cart);
        }),
        tap((cart) => this.cart$.next(cart))
      );
    } else {
      return EMPTY;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public orderCart(payPalOrderId?: string, paypalCaptureMockResponse?: { mock_application_codes: string }): Observable<IOrderCartResponse> {
    return this._httpClient.post<IOrderCartResponse>(
      'api/cart/order',
      payPalOrderId ? { PayPalOrderID: payPalOrderId, PayPalCaptureMockResponse: paypalCaptureMockResponse } : null
    );
  }

  public requestOffer(): Observable<IOfferCartResponse> {
    return this._httpClient.post<IOfferCartResponse>('api/cart/offer', null);
  }

  public setCartRemark(remark: string): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: remark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartDescription(description: string): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: description,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartReference(reference: string): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: reference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartPositionRemark(position: Partial<IGenericPosition>, remark: string): Observable<ICart> {
    const offerArchivePositions = CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions);
    const items = CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions);

    const positionId = position.PositionId || position.PositionItemId;

    offerArchivePositions.map((offerArchivePosition) => {
      const currentPosition = offerArchivePosition;

      if (
        offerArchivePosition.CartOfferArchivePositionId === positionId &&
        this._isSameSetPositionQuantityConfiguration(offerArchivePosition.CartOfferArchivePositionSetPositionQuantities, position.PositionSetPositionQuantities)
      ) {
        currentPosition.CartPositionRemark = remark;
      }

      return currentPosition;
    });

    items.map((cartItem) => {
      const currentPosition = cartItem;

      if (cartItem.CartItemId === positionId) {
        currentPosition.CartPositionRemark = remark;
      }

      return currentPosition;
    });

    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: offerArchivePositions,
      CartItems: items,
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setAddresses(deliveryAddressData?: ISetAddressData, invoiceAddressData?: ISetAddressData): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    let deliveryAddressAdditionalInformation = deliveryAddressData ? undefined : this.cart$.value.CartDeliveryAddressAdditionalInformation;
    let invoiceAddressAdditionalInformation = invoiceAddressData ? undefined : this.cart$.value.CartInvoiceAddressAdditionalInformation;

    if (
      deliveryAddressData &&
      deliveryAddressData.Address.AddressName2 !== this.cart$.value.CartDeliveryAddressAdditionalInformation &&
      deliveryAddressData.AdditionalInformationChange
    ) {
      deliveryAddressAdditionalInformation = deliveryAddressData.Address.AddressAdditionalInformation || null;
    }

    if (
      invoiceAddressData &&
      invoiceAddressData.Address.AddressName2 !== this.cart$.value.CartInvoiceAddressAdditionalInformation &&
      invoiceAddressData.AdditionalInformationChange
    ) {
      invoiceAddressAdditionalInformation = invoiceAddressData.Address.AddressAdditionalInformation || null;
    }

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: deliveryAddressData ? deliveryAddressData.Address.AddressUUID : this.cart$.value.CartDeliveryAddressUUID,
      CartDeliveryAddressIsCustomerAddress: deliveryAddressData ? !deliveryAddressData.Address.AddressUUID : undefined,
      CartInvoiceAddressIsCustomerAddress: invoiceAddressData ? !invoiceAddressData.Address.AddressUUID : undefined,
      CartInvoiceAddressUUID: invoiceAddressData ? invoiceAddressData.Address.AddressUUID : this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: deliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: invoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setLicenseAddress(licenseAddress: ILicenseAddress): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: licenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartWarrantySerialNumbers(positions: Partial<IGenericPosition>[]): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(positions),
      CartItems: CartMappers.mapCartElementsToCartItems(positions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartInvoiceAddressAsDeliveryAddress(useInvoiceAddressAsDeliveryAddress: boolean): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: useInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCostCenter(costCenterUUID: string): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CostCenterUUID: costCenterUUID,
    });
  }

  public setCartUsePartialDelivery(usePartialDelivery: boolean): Observable<ICart> {
    this.skipSnackBar = true;
    this.skipRipple = true;

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: usePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartDeliveryDateTypeID(deliverySpecification: number): Observable<ICart> {
    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: deliverySpecification,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate as moment.Moment,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  public setCartDeliveryDate(deliveryDate: moment.Moment): Observable<ICart> {
    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: deliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  protected _setCartSetPositionConfiguration(cartOfferArchivePositions: ICartOfferArchivePosition[]): Observable<ICart> {
    return this._setCart({
      CartItems: this._mapCartElementsToCartItems(this.cart$.value.CartPositions),
      CartOfferArchivePositions: cartOfferArchivePositions,
      CartRemark: this.cart$.value.CartRemark,
      CartReference: this.cart$.value.CartReference,
      CartDescription: this.cart$.value.CartDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartDeliveryDateTypeID: this.cart$.value.CartDeliveryDateTypeID,
      CartDeliveryDate: this.cart$.value.CartDeliveryDate,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }

  private _setCart(cart: Partial<ISetCartRequest>, snackBarMessage?: string): Observable<ICartGeneric> {
    const cartTemporarySessionUUID = this._authenticationService.getCartTemporarySessionUUIDFromLocalStorage();

    cart.CartOfferArchivePositions.map((position) => {
      delete position.CartOfferArchivePositionSalesPrice;
      delete position.CartOfferArchivePositionSalesPriceTotal;
      delete position.CartOfferArchivePositionSalesPriceInclusiveVAT;
      delete position.CartOfferArchivePositionItemVATs;

      return position;
    });

    return this._httpClient
      .put<ICartNonGeneric>(`api/cart` + (cartTemporarySessionUUID ? `/${cartTemporarySessionUUID}` : ''), {
        CartOfferArchivePositions: cart.CartOfferArchivePositions,
        CartItems: cart.CartItems,
        CartRemark: cart.CartRemark,
        CartReference: cart.CartReference,
        CartDescription: cart.CartDescription,
        CartDeliveryAddressUUID: cart.CartDeliveryAddressUUID,
        CartDeliveryAddressIsCustomerAddress: cart.CartDeliveryAddressIsCustomerAddress,
        CartInvoiceAddressUUID: cart.CartInvoiceAddressUUID,
        CartInvoiceAddressIsCustomerAddress: cart.CartInvoiceAddressIsCustomerAddress,
        UseInvoiceAddressAsDeliveryAddress: cart.UseInvoiceAddressAsDeliveryAddress,
        CartDeliveryAddressAdditionalInformation: cart.CartDeliveryAddressAdditionalInformation,
        CartInvoiceAddressAdditionalInformation: cart.CartInvoiceAddressAdditionalInformation,
        CartTemporarySessionUUID: this._authenticationService.getCartTemporarySessionUUIDFromLocalStorage(),
        UsePartialDelivery: cart.UsePartialDelivery,
        CartDeliveryDateTypeID: cart.CartDeliveryDateTypeID,
        CartDeliveryDate:
          cart.CartDeliveryDate !== undefined && moment(cart.CartDeliveryDate).isValid() ? moment.utc(cart.CartDeliveryDate).local().format() : undefined,
        CartLicenseAddress: cart.CartLicenseAddress,
        CostCenterUUID: cart.CostCenterUUID,
      })
      .pipe(
        map((updatedCart) => this._mapCartToGenericCart(updatedCart)),
        tap((updatedCart: ICartGeneric) => {
          this._updateCartIcon(updatedCart);

          this.cart$.next(updatedCart);
          this._loadingService.isLoading$.next(false);

          if (snackBarMessage && !this.skipSnackBar) {
            this._matSnackBar.open(snackBarMessage, 'OK', { duration: 10000 });
          }

          this.skipSnackBar = false;
        })
      );
  }

  private _updateCartIcon(cart: ICartGeneric): void {
    const cartItemQuantity = cart.CartPositions.reduce(
      (totalCartItemQuantity, currentCartItem) => totalCartItemQuantity + currentCartItem.PositionItemQuantity,
      0
    );

    if (window.MCLCR && typeof window.MCLCR.updateCartIcon === 'function' && document.querySelector('[data-cart-total]')) {
      window.MCLCR.updateCartIcon(cartItemQuantity);
    }
  }

  private _mapOfferPositionsToCartOfferArchivePositions(offerPositions: Partial<IGenericPosition>[]): ICartOfferArchivePosition[] {
    return offerPositions
      .filter((offerPosition) => {
        return !(
          offerPosition.PositionTypeDescriptionEN === 'Text' ||
          (offerPosition.PositionIsOptional && !offerPosition.PositionIsOptionalActivated) ||
          (offerPosition.PositionIsAlternative && !offerPosition.PositionIsAlternativeActivated)
        );
      })
      .map((offerPosition, index) => {
        return {
          CartOfferArchivePositionId: offerPosition.PositionId,
          CartOfferArchivePositionQuantity: offerPosition.PositionItemQuantity,
          CartPositionRemark: offerPosition.PositionCustomerRemark,
          CartPositionWarrantySerialItemNo: offerPosition.PositionWarrantySerialItemNo,
          CartPositionWarrantySerialNumbers: offerPosition.PositionWarrantySerialNumbers,
          CartPositionSortOrder: index,
          CartOfferArchivePositionSetPositionQuantities: offerPosition.PositionSetPositionQuantities || [],
        } as ICartOfferArchivePosition;
      })
      .filter((cartOfferArchivePosition) => !!cartOfferArchivePosition);
  }

  public addOfferPositionsToCart(offer: IDocument): Observable<ICartGeneric> {
    const offerPositionsToBeAdded = [];

    offer.DocumentPositions.forEach((documentPosition) => {
      const tempPosition: Partial<IGenericPosition> = this._dereferencePosition(documentPosition, {
        PositionChildPositions: [],
        PositionSetPositionQuantities: [],
      });

      documentPosition.PositionChildPositions.forEach((documentChildPosition) => {
        if (this._compareForOptionalAndAlternative(documentChildPosition)) {
          tempPosition.PositionSetPositionQuantities.push({
            PositionId: documentChildPosition.PositionId,
            Quantity: documentChildPosition.PositionItemQuantity,
          });

          tempPosition.PositionChildPositions.push(
            this._dereferencePosition(documentChildPosition, {
              PositionChildPositions: [...(documentChildPosition.PositionChildPositions || [])],
            })
          );
        }
      });

      offerPositionsToBeAdded.push(tempPosition);
    });

    const cartOfferArchivePositions = CartMappers.mapCartElementsToCartOfferArchivePositions(this.cart$.value.CartPositions) || [];
    const addedCartOfferArchivePositions = this._mapOfferPositionsToCartOfferArchivePositions(offerPositionsToBeAdded) || [];
    const cartItems = CartMappers.mapCartElementsToCartItems(this.cart$.value.CartPositions.filter((position) => position.PositionId === 0)) || [];

    const nextCartPositionSortOrder = this.getNextSortOrder({ CartOfferArchivePositions: cartOfferArchivePositions, CartItems: cartItems });

    addedCartOfferArchivePositions.forEach((cartElement, index) => {
      cartElement.CartPositionSortOrder = nextCartPositionSortOrder + index;
    });

    const mergedCartOfferArchivePositions = [
      ...cartOfferArchivePositions, // Items already inside cart
      ...addedCartOfferArchivePositions, // Items from document to be added to the cart
    ];

    const newCart: Partial<ISetCartRequest> = {
      CartOfferArchivePositions: mergedCartOfferArchivePositions,
      CartItems: cartItems,
      CartRemark: offer.DocumentInternalRemark,
      CartReference: offer.DocumentCustomerReferenceNo,
      CartDescription: offer.DocumentDescription,
      CartDeliveryAddressUUID: this.cart$.value.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: this.cart$.value.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    };

    return this._errorHandlingService.handleErrorWithBannerAndRetry<ICartGeneric>({
      endpoint: this._setCart(newCart, this._translationService.translations.snackbar.AddedItemsToCart.toString()),
      propertyModification: null,
      bannerTitle: this._translationService.translations.error.CartAction.toString(),
    });
  }

  public getItemQuantity(position: Partial<IGenericPosition>): number {
    const matchingPosition = this.cart$.value.CartPositions.find((cartPosition) => {
      return position.PositionId === cartPosition.PositionId || (position.PositionItemNo && position.PositionItemNo === cartPosition.PositionItemNo);
    });

    return matchingPosition?.PositionItemQuantity || 0;
  }

  public saveSortedCart(currentCart: ICartGeneric): Observable<ICartGeneric> {
    currentCart.CartPositions.forEach((cartPosition, index) => (cartPosition.PositionSortOrder = index));

    return this._setCart({
      CartOfferArchivePositions: CartMappers.mapCartElementsToCartOfferArchivePositions(currentCart.CartPositions),
      CartItems: CartMappers.mapCartElementsToCartItems(currentCart.CartPositions),
      CartRemark: currentCart.CartRemark,
      CartReference: currentCart.CartReference,
      CartDescription: currentCart.CartDescription,
      CartDeliveryAddressUUID: currentCart.CartDeliveryAddressUUID,
      CartInvoiceAddressUUID: currentCart.CartInvoiceAddressUUID,
      UseInvoiceAddressAsDeliveryAddress: this.cart$.value.UseInvoiceAddressAsDeliveryAddress,
      UsePartialDelivery: this.cart$.value.UsePartialDelivery,
      CartDeliveryAddressAdditionalInformation: this.cart$.value.CartDeliveryAddressAdditionalInformation,
      CartInvoiceAddressAdditionalInformation: this.cart$.value.CartInvoiceAddressAdditionalInformation,
      CartLicenseAddress: this.cart$.value.CartLicenseAddress,
      CostCenterUUID: this.cart$.value.CostCenterUUID,
    });
  }
}
