import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITranslatedDescription } from '../interfaces/dropdown-values.interface';

export interface IDashboardOpenInvoice {
  DocumentNo: string;
  DocumentUUID: string;
  DocumentPaymentDate: string; // date
  CurrencyISOSymbol: string;
  DocumentPaymentRemainingAmountGross: number;
  DocumentSalesPriceTotalNet: number;
  DocumentSalesPriceTotalGross: number;
}

export interface IDashboardOpenPosition {
  DocumentNo: string;
  DocumentPositionStateDescription: { en: string; de: string };
  DocumentPositionExpectedDeliveryDateState: string;
  DocumentPositionItemDescription: string;
  DocumentExpectedDeliveryDate: string; // date
  DocumentPositionOpenQuantityTotal: number;
  DocumentPositionQuantityKeyDescription: ITranslatedDescription;
  DocumentPositionQuantityKeySymbol: ITranslatedDescription;
}

export interface IDashboardOffer {
  DocumentNo: string;
  DocumentUUID: string;
  CurrencyISOSymbol: string;
  DocumentDate: string; // date
  DocumentDescription: string;
  DocumentSalesPriceNet: number;
  DocumentSalesPriceGross: number;
}

export interface IDashboardOrder {
  DocumentNo: string;
  DocumentUUID: string;
  DocumentDate: string; // date
  DocumentDescription: string;
  DocumentExpectedDeliveryDate: string; // ISO Date
  DocumentStateID: number;
  DocumentStateName: string;
  DocumentStates: {
    Description: {
      de: string;
      en: string;
    };
    State: number;
    Date: string;
    AdditionalInfo: boolean;
  }[];
  DocumentHasMultipleStates: boolean;
  DocumentCostCenterResponsibleContactUUID: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private _httpClient: HttpClient) {}

  public getDashboardOffers(): Observable<IDashboardOffer[]> {
    return this._httpClient.get<IDashboardOffer[]>('api/dashboard/offers');
  }

  public getDashboardOrders(): Observable<IDashboardOrder[]> {
    return this._httpClient.get<IDashboardOrder[]>('api/dashboard/orders');
  }

  public getDashboardOpenInvoices(): Observable<IDashboardOpenInvoice[]> {
    return this._httpClient.get<IDashboardOpenInvoice[]>('api/dashboard/openInvoices');
  }

  public getDashboardOpenPositions(): Observable<IDashboardOpenPosition[]> {
    return this._httpClient.get<IDashboardOpenPosition[]>('api/dashboard/openPositions');
  }
}
