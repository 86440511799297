<div class="order-status-container">
  <div class="visual-state-indicator">
    @for (state of order.DocumentStates; track state; let i = $index) {
      <div
        [class.previous-state]="state.State === orderState.PREVIOUS"
        [class.current-state]="state.State === orderState.CURRENT"
        [class.following-state]="state.State === orderState.FOLLOWING"
        [class.second-before]="i === order.DocumentStateID - 3"
        [class.third-before]="i === order.DocumentStateID - 4"
        [class.fourth-before]="i === order.DocumentStateID - 5"
        [class.fifth-before]="i === order.DocumentStateID - 6"
        [class.sixth-before]="i === order.DocumentStateID - 7"
        [class.seventh-before]="i === order.DocumentStateID - 8"
        [class.eighth-before]="i === order.DocumentStateID - 9"
        [class.second-behind]="i === order.DocumentStateID + 1"
        [class.third-behind]="i === order.DocumentStateID + 2"
        [class.fourth-behind]="i === order.DocumentStateID + 3"
        [class.fifth-behind]="i === order.DocumentStateID + 4"
        [class.sixth-behind]="i === order.DocumentStateID + 5"
        [class.seventh-behind]="i === order.DocumentStateID + 6"
        [class.eighth-behind]="i === order.DocumentStateID + 7"
        [style.transform]="'translate(calc(-75% + (' + (i - 4 + (order.DocumentStateID < 4 ? 1 : 0)) * 35 + 'px)), -50%)'"
      >
        @switch (i) {
          @case (0) {
            <mat-icon svgIcon="enthus-status_order_received"></mat-icon>
          }
          @case (1) {
            <mat-icon svgIcon="enthus-status_order_accepted"></mat-icon>
          }
          @case (2) {
            <mat-icon>published_with_changes</mat-icon>
          }
          @case (3) {
            <mat-icon svgIcon="enthus-status_in_progress"></mat-icon>
          }
          @case (4) {
            <mat-icon svgIcon="enthus-status_reserved"></mat-icon>
          }
          @case (5) {
            <mat-icon svgIcon="enthus-status_picking"></mat-icon>
          }
          @case (6) {
            <mat-icon svgIcon="enthus-status_production"></mat-icon>
          }
          @case (7) {
            <mat-icon svgIcon="enthus-status_shipped"></mat-icon>
          }
          @case (8) {
            <mat-icon svgIcon="enthus-status_delivered"></mat-icon>
          }
        }
      </div>
    }
  </div>
  <div class="current-state-description">
    @if (order.DocumentExpectedDeliveryDate) {
      <p class="bold">
        {{ translationService.translations.labels.ExpectedDelivery }}: {{ order.DocumentExpectedDeliveryDate | date: translationService.currentDateFormat }}
      </p>
    }
    <a mat-raised-button [routerLink]="['/documents/orders/' + order.DocumentNo]" color="primary">
      {{ translationService.translations.action.ShowDetails }}
    </a>
  </div>
  <div class="states">
    <mat-tab-group #matTabGroup>
      <mat-tab>
        <div class="state-row-container">
          @for (state of order.DocumentStates; track state) {
            <div
              class="state-row"
              [class.active]="state.State === orderState.CURRENT"
              [class.no-tracking-available-by-shipped-state]="!state.AdditionalInfo && state.Description.en === 'Shipped' && order.DocumentStateID === 6"
              [class.pending-approval]="state.Description.en === 'Approval pending' && order.DocumentStateName === 'approval_pending'"
            >
              <div class="left-column">
                <mat-icon [svgIcon]="'enthus-lens'"></mat-icon>
              </div>
              <div [class.bold]="state.State === orderState.CURRENT" class="center-column">
                <span>{{ state.Description[translationService.locale] }}&nbsp;</span>
                @if (state.AdditionalInfo) {
                  <a class="link-text-color" (click)="matTabGroup.selectedIndex = 1">{{ translationService.translations.information.More }}</a>
                }
                @if (!state.AdditionalInfo && state.Description.en === 'Shipped' && order.DocumentStateID === 6) {
                  <a class="no-tracking-available-text"> ({{ translationService.translations.information.NoTrackingAvailable }}) </a>
                }
              </div>
              <div [class.bold]="state.State === orderState.CURRENT" class="right-column">
                {{ state.Date | date: translationService.currentDateFormat }}
              </div>
            </div>
          }
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <button class="back" mat-icon-button (click)="shipmentListComponent.backButtonClicked.next()">
            <mat-icon [svgIcon]="'enthus-arrow_back'"></mat-icon>&nbsp;{{ translationService.translations.action.Back }}
          </button>
          <portal-shipment-list
            [document]="$any(order)"
            [showGeneralInfoHeader]="false"
            (goBack)="matTabGroup.selectedIndex = 0"
            #shipmentListComponent
          ></portal-shipment-list>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
